.text-input {
}

.text-input input {
  color: white;
  background-color: #151615 !important;
  border-radius: 30px !important;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 4px 4px 0px #00000073 inset;
  border: 1px solid var(--dark-300);
  font-size: 15px;
  font-weight: 400;
  padding: 8px 16px 8px 16px;
}

.text-input fieldset {
  border-color: transparent !important;
}

.text-input:disabled {
  pointer-events: none;
  color: #f8f8f87d;
  opacity: 1;
}
