.toastContainer {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: calc(100% - 40px);
  pointer-events: none;
  width: 100%;
  align-items: center;
}

.superToast {
  display: flex;
  flex-direction: row;
  height: 48px;
  padding: 16px;
  background-color: #181818 !important;
  color: #cbcbcb !important;
  border-radius: 5px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  pointer-events: auto;
  align-items: center;
  gap: 10px;

  &.info {
    background-color: #3498db;
  }

  &.success {
    background-color: #2ecc71;
  }

  &.error {
    background-color: #e74c3c;
  }

  &.warning {
    background-color: #f1c40f;
    color: #000;
  }
}

.iconAndText {
  display: flex;
  gap: 10px;
  align-items: center;
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.single {
  color: #4A4A4A;
}

.icon {
  cursor: pointer;
}