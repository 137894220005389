.super-button {
  height: 48px;
  background-color: var(--Surface-Background, rgba(40, 40, 40, 0.7)) !important;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border-radius: 32px !important;
  border: none;
  cursor: pointer;
  opacity: var(--sds-size-stroke-border);
  background-blend-mode: luminosity;
  box-shadow: 2px 4px 16px 0px rgba(248, 248, 248, 0.06) inset;
  backdrop-filter: blur(50px);
  text-transform: none !important;
}

.super-button:hover {
  filter: brightness(1.4);
}

.brand-secondary {
  background-color: var(--brand-secondary) !important;
  color: var(--dark-900) !important;
}

.clear-cancel {
  color: var(--light-200) !important;
  text-transform: none !important;
  &:hover {
    background-color: transparent;
  }
}
