@use "../../../styles/functions.scss" as utils;

.logo {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  transition: all 0.5s;
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: utils.rem(150);
  height: utils.rem(150);
}

.wrapper img {
  width: 100%;
  height: 100%;
}

.logoOriginal {
  position: relative;
  z-index: 99;
}

.logoPulse {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
  filter: blur(0px);
  animation: pulse2 2s infinite ease-in-out;
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.4;
    filter: blur(4px);
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
    opacity: 0;
  }
}
