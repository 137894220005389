.title {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}

.description {
  color: rgba(255, 255, 255, 0.90);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}