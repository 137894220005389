@use "./functions.scss" as utils;
@import url("./components/index.scss");
@import url("./variables.scss");
// @import url("./animations.scss");
// @import url("./functions.scss");
@import url("./reset.scss");
@import url("./typography.scss");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content {
  position: relative;
  z-index: 1;
  color: var(--light-100);
  overflow: hidden;
  transition: all 1s ease;
  height: 100vh;
  overflow-y: scroll;
}

.page-layout {
  padding: 2rem;
}

.bg-blur {
  backdrop-filter: blur(20px);
}

.text-bold {
  font-weight: 500;
}

.text-dimmed {
  color: var(--dark-100);
}

.h-70 {
  height: 70vh;
}

.brand-secondary-bg {
  background-color: var(--brand-secondary) !important;
}

.text-white {
  color: var(--light-100) !important;
}

.text-dark {
  color: var(--dark-700) !important;
}

.super-button:hover {
  filter: none !important;
}

.w-100 {
  width: 100%;
}

.page-content {
  width: 100%;
  height: unset;
  padding: utils.rem(20);
  border-radius: 1rem;
  background: #FFFFFF14;
  box-shadow: 2px 4px 16px 0px #F8F8F80F inset;
  backdrop-filter: blur(utils.rem(20));
}

.page-content-wrapper {
  background-color: #72727221;
  padding: 24px 16px 24px 16px;
  height: 75vh;
  overflow-y: scroll;
  border-radius: 8px;
}

.round-image-with-border {
  border-radius: 100%;
  object-fit: cover;
  border: 0.125rem solid var(--theme-color);
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .page-layout {
    padding: 1rem;
  }

  .page-content {
    background: transparent;
    box-shadow: none;
    backdrop-filter: none;
    padding: 0px;
    margin: 8px 0px 16px;
  }

  .page-content-wrapper {
    background-color: transparent;
    padding: utils.rem(8);
    height: 85vh;
  }
}